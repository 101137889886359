<template>
  <div v-resize="onResize">
    <v-toolbar>
      <v-text-field
        v-model="searchText"
        prepend-inner-icon="mdi-magnify"
        placeholder="Filter users"
        dense
        filled
        rounded
        hide-details
      />
      <v-spacer />
     <edit-profile :value="{}" :isUpdate="false"></edit-profile>
    </v-toolbar>
    <virtual-list
      :style="{ height: `${height}px` }"
      class="stream scroll-touch mb-3"
      :data-key="'username'"
      :estimate-size="60"
      :data-sources="users"
      :data-component="item"
      ref="vsl"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {
    "virtual-list": () => import("vue-virtual-scroll-list"),
    'edit-profile': () => import("@/components/EditProfile.vue")
  },
  computed: {
    ...mapState("users", ["users"])
  },
  data: () => ({
    item: () => import("../components/User.vue"),
    searchText: "",
    height: window.innerHeight - 130
  }),
  methods: {
    ...mapActions("users", ["fetchUsers"]),
    onResize() {
      this.height = window.innerHeight - 130;
    }
  },
  mounted() {
    this.fetchUsers();
    this.onResize();
  }
};
</script>

<style scoped>
.stream {
  position: relative;
  width: 100%;
  overflow-y: auto;
}
</style>
